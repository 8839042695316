import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'

import moment from 'moment-timezone'
import 'moment/locale/ru'
Vue.use(require('vue-moment'), {
  moment
})

import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
if(process.env.NODE_ENV === 'development') {
  Vue.axios.defaults.baseURL = 'http://127.0.0.1:82/v1'
} else {
  Vue.axios.defaults.baseURL = 'https://api.portal.ao-nk.ru/v1'
}

Vue.config.productionTip = false
Vue.config.$moment = moment;
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
