<template>
  <v-container>
    <v-card elevation="12" rounded class="pa-3">
      <v-card-title>
        Поиск
        <v-spacer></v-spacer>
        <div class="d-flex justify-space-between">
        </div>
      </v-card-title>
      <v-card-text>
        <v-text-field
            class="pr-3"
            v-model="name"
            label="ФИО (полностью)"
            single-line
          ></v-text-field>
          <v-text-field
            class="pr-3"
            v-model="regNum"
            label="Номер регистрации в реестре"
            single-line
          ></v-text-field>
          <div class="red--text mb-3" v-if="error">Не найдено</div>
          <v-btn color="primary" @click="search" append-icon="mdi-magnify" :loading="loading">Поиск</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
export default {
  data() {
    return {
      name: "",
      regNum:"",
      loading: false,
      error:false
    };
  },
  methods: {
    search() {
      this.loading = true;
      this.$http
        .post("experts-reg/find", {
          name: this.name,
          regNum: this.regNum,
        })
        .then((response) => {
          this.$router.push('/'+response.data)
        })
        .catch(() => {
          this.error=true
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch:{
    regNum(){
      this.error=false
    },
    name(){
      this.error=false
    }
  }
};
</script>

<style>
</style>